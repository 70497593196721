.layout-home {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: #fff;
}

.layout-home .site-layout-content {
    padding: 50px;
    background: #f0f2f5;
    margin: 20px;
}

.layout-home .site-layout-content .contents {
    height: 100%;
}

.layout-home .ant-btn {
    height: 50px;
    width: 250px;
    font-size: 18px;
    border-radius: 10px;
    margin: 10px;
    line-height: 50px;
}

.layout-home .welcome-container {
    background: linear-gradient(
            rgba(255, 255, 255, 0.1),
            rgba(255, 255, 255, 0.1)
        ),
        url('/src/images/moment.jpeg');
    background-size: cover;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}

.layout-home .welcome-text {
    color: #fff;
    background: rgb(24, 144, 255);
    left: 20px;
    bottom: 5px;
    position: absolute;
    padding: 0px 10px;
}

.layout-home .actions-container {
    text-align: center;
}

.layout-home .actions-box {
    background: #fff;
    display: inline-block;
    padding: 20px 50px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    margin-top: 20%;
    box-shadow: 0 2px 4px rgb(0 0 0 / 10%), 0 8px 16px rgb(0 0 0 / 10%);
    box-sizing: border-box;
}

.layout-home .ant-layout-footer {
    text-align: right;
    background: transparent;
    padding: 0px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
}

@media only screen and (max-width: 576px) {
    .layout-home .ant-btn {
        height: 40px;
        padding: 6.4px 20px;
        font-size: 11px;
        border-radius: 10px;
        width: auto;
        height: 50px;
    }
    .layout-home .actions-box {
        width: auto;
        box-shadow: none;
        background:transparent;
        padding: 0px;
        margin: 0px;
    }
}
