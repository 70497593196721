.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

.trigger:hover {
    color: #1890ff;
}

.layout-dashboard .site-layout-background {
    background: #fff;
}

.layout-dashboard .logo {
    text-align: center;
    float: none;
}