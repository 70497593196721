#root {
    height: 100%;
}

.flex-center {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/* grid helper */
/* div {
    outline: 1px dashed blue;
} */