.signup-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.signup-form {
    width: 400px;
}

.signup-form-button {
    width: 100%;
}