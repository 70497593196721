.login-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.login-form {
    width: 400px;
}
 .login-form-forgot {
    float: right;
}
.login-form-forgot {
    float: left;
}
.login-form-button {
    width: 100%;
}